<template>
  <section class="partners">
    <default-layout-section class="partners__content">
      <h2
        v-if="title"
        class="partners__title g-h2"
      >
        {{ title }}
      </h2>
      <ul class="partners__list">
        <li
          v-for="(partner, key) in items"
          :key="key"
          class="partners__item"
        >
          <a
            :href="partner.href"
            class="partners__link"
            target="_blank"
            :title="partner.name"
          >
            <img
              :src="partner.logo"
              :alt="partner.name"
            >
          </a>
        </li>
      </ul>
    </default-layout-section>
  </section>
</template>

<script>
export default {
  name: 'Partners',
  props: {
    title: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => ([])
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/variables.scss";

.partners {
  &__content {
    position: relative;
    z-index: 1;
    padding: 40px 16px;

    @media #{$md} {
      padding: 40px 24px;
    }

    @media #{$lg} {
      padding: 40px 80px;
    }

    @media #{$xl} {
      padding: 40px 100px;
    }
  }

  &__title {
    margin: 0 0 40px 0;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -20px -8px;
    padding: 0;
    list-style: none;

    @media #{$md} {
      margin: -20px -12px;
    }
  }

  &__item {
    margin: 20px 8px;
    padding: 12px;
    width: 156px;

    @media #{$sm} {
      width: 183px;
    }

    @media #{$md} {
      margin: 20px 12px;
      width: 162px;
    }

    @media #{$lg} {
      width: 198px;
    }

    @media #{$xl} {
      width: 228px;
    }
  }

  &__link {
    display: block;
    line-height: 0;
  }
}
</style>
