/**
 * Максимальный тип лендинга
 * @type {string}
 */
export const MAX_LANDING_TYPE = 'max'
/**
 * Средний тип лендинга
 * @type {string}
 */
export const MID_LANDING_TYPE = 'mid'
/**
 * Минимальный тип лендинга
 * @type {string}
 */
export const MIN_LANDING_TYPE = 'min'
/**
 * Тип лендинга только с платформой (без шапки и подвала)
 * @type {string}
 */
export const ONLY_PLATFORM_TYPE = 'only-platform'
/**
 * Тип присутствия зрителя offline
 * @type {string}
 */
export const OFFLINE_VIEWER_TYPE = 'offline'
/**
 * Имя сборщика для mashroom
 * @type {string}
 */
export const MASHROOM_BUILDER_NAME = 'mashroom'
/**
 * Имя сборщика для vtb
 * @type {string}
 */
export const WECLOUD_BUILDER_NAME = 'wecloud'
