import axios from 'axios'
import { API_STAND_URLS } from '@/constants/stands'

const http = axios.create({
  baseURL: API_STAND_URLS[process.env.VUE_APP_STAND]
})

http.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error)
)

http.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
)

export default http
