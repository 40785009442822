import { createApp } from 'vue'
import App from './App.vue'

import store from './store'
import VueScrollTo from 'vue-scrollto'
import mdiVue from 'mdi-vue/v3'
import * as mdijs from '@mdi/js'

import 'normalize.css'

import BreakpointsMixin from '@/mixins/breakpoints'

import DefaultLayoutSection from '@/views/layouts/section'

const app = createApp(App)

app.component('DefaultLayoutSection', DefaultLayoutSection)

app.mixin(BreakpointsMixin)

app.use(mdiVue, {
  icons: mdijs
})
app.use(VueScrollTo, {
  offset () {
    const header = document.querySelector('.app-header')

    // делаем отступ при скролле противоположный высоте header
    if (header) {
      return -1 * header.offsetHeight
    }

    return 0
  }
})

app.use(store)

app.mount('#app')
