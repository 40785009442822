import mashroomViewConfigurations from '@/configurations/view/mashroom'
import vtbViewConfigurations from '@/configurations/view/wecloud'
import { MASHROOM_BUILDER_NAME, WECLOUD_BUILDER_NAME } from '@/constants'

let viewConfig = mashroomViewConfigurations
const isCorrectBuilderName = [MASHROOM_BUILDER_NAME, WECLOUD_BUILDER_NAME].includes(process.env.VUE_APP_BUILDER_NAME)

if (isCorrectBuilderName && process.env.VUE_APP_BUILDER_NAME === WECLOUD_BUILDER_NAME) {
  viewConfig = vtbViewConfigurations
}

export default viewConfig
