//  Закомментируйте строку ниже если блок "Спикеры" не будет использоваться
import SpeakersConfiguration from '@/configurations/content/mashroom/speakers'
//  Закомментируйте строку ниже если блок "Партнеры" не будет использоваться
import PartnersConfiguration from '@/configurations/content/mashroom/partners' // @/configurations/content/partners-dark для темной темы

export default {
  pageTitle: 'Enterprise Agile Russia 2024',
  logo: {
    image: require('@/assets/logo/EAR Logo 240x240.png'), // @/assets/logo/logo-white.svg для темной темы
    alt: 'Mashroom logo'
  },
  pageBackgroundImage: require('@/assets/background/conf-enterprise-agile-ru-backgrnd.png'), // путь до кастомного фона
  aboutActivity: {
    name: 'Enterprise Agile Russia 2024',
    description: [
      'Конференция об ускорении и цифровой трансформации крупных компаний.',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      ''
    ],
    date: '18.11.2024',
    time: '9:00 - 19:00'
  },
  activities: [
    {
      tabName: 'Выберите', // название таба, при многозальном мероприятии
      items: [
        {
          hash: 'pLyiWNyW',
          id: 5220,
          hallName: 'Первый зал' // название зала
        },
        {
          hash: 'ZTuhyIpW',
          id: 5221,
          hallName: 'Второй зал' // название зала
        }
      ]
    }
  ],
  player: {
    title: 'Трансляция' // заголовок блока "Плеер"
  },
  schedule: {
    title: 'Расписание' // заголовок блока "Расписание"
  },
  speakers: {
    //  Закомментируйте строку ниже если блок "Спикеры" не будет использоваться
    items: SpeakersConfiguration,
    title: 'Спикеры', // заголовок блока "Спикеры"
    shape: 'circle' // форма фотографий спикеров ('square' - квадрат, 'circle' - круг)
  },
  imageBlock: {
    title: 'Принять оченое участие', // заголовок Блока с картинкой (оставить пустым (''), если не нужен)
    image: require('@/assets/image-block/ghost1.png'), // путь до изображения для блока с картинкой
    items: [
      'Обладателей билетов для очного формата мы ждём с 9:00 мск 18 ноября 2024 по адресу: ',
      'г. Москва, Ленинградский проспект, 36 стр. 31',
      'Hyatt Regency Moscow Petrovsky Park',
      'метро Динамо или Петровский Парк',
      '',
      'Купить очные билеты можно до 16:00 мск 14.11.2024 здесь. (нужна ссылка)',
      '',
      ''
    ]
  },
  info: {
    title: 'Очно', // заголовок Текстового блока (оставить пустым (''), если не нужен)
    items: [
      'Обладателей билетов для очного формата мы ждём с 9:00 мск 18 ноября 2024 по адресу: г. Москва, Ленинградский проспект, 36 стр. 31. Hyatt Regency Moscow Petrovsky Park, метро Динамо или Петровский Парк.',
      '',
      'Купить очные билеты можно до 16:00 мск 14.11.2024 <a href="https://enterprise-agile.ru/?utm_source=conf-online#join" target="_blank">здесь</a>.',
      '',
      ''
    ]
  },
  partners: {
    //  Закомментируйте строку ниже если блок "Партнеры" не будет использоваться
    items: PartnersConfiguration,
    title: 'Партнеры' // заголовок блока "Партнёры"
  },
  footer: {
    copyright: '© Mashroom,',
    phone: ''
  }
}
