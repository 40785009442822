<template>
  <footer class="app-footer">
    <span class="app-footer__copyright">{{ copyright }} {{ year }}</span>
    <a
      v-if="phone"
      class="app-footer__phone"
      :href="`tel:${phone}`"
    >{{ phone }}</a>
    <div
      v-if="isShowLabel"
      class="app-footer__platform-label"
    >
      Сделано в
      <a
        class="app-footer__logo"
        href="https://mashroom.live/"
        target="_blank"
      >
        <img
          src="@/assets/mashroom.svg"
          alt="Mashroom"
        >
      </a>
    </div>
  </footer>
</template>

<script>
import configurations from '@/configurations'

export default {
  name: 'AppFooter',
  props: {
    isShowLabel: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      copyright: configurations.content.footer.copyright,
      year: new Date().getFullYear(),
      phone: configurations.content.footer.phone
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/variables.scss";

.app-footer {
  position: relative;
  padding: 24px 16px;
  font-size: 12px;
  line-height: 1.3;
  color: var(--footer-text-color);
  text-align: center;
  background-color: var(--footer-background);

  &__phone {
    display: block;
    margin-top: 8px;
    color: inherit;
    text-decoration: none;
  }

  &__platform-label {
    padding-top: 16px;
    font-size: 10px;
    font-weight: 450;
    line-height: 1.4;
  }

  &__logo {
    display: block;
    line-height: 0;
  }
}
</style>
