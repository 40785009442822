/**
 * @const {number} SM_BREAKPOINT - sm точка медиа-выражения
 */
export const SM_BREAKPOINT = 414
/**
 * @const {number} MD_BREAKPOINT - md точка медиа-выражения
 */
export const MD_BREAKPOINT = 768
/**
 * @const {number} LG_BREAKPOINT - lg точка медиа-выражения
 */
export const LG_BREAKPOINT = 1040
/**
 * @const {number} XL_BREAKPOINT - xl точка медиа-выражения
 */
export const XL_BREAKPOINT = 1440
