import { api } from './api'
import to from 'await-to-js'

export default {
  async getEvents ({ commit }, hallId) {
    const [error, response] = await to(api.getEvents(hallId))

    if (response) {
      let { events = [] } = response.data

      if (!events) events = []

      commit('setEvents', events)

      return Promise.resolve(response)
    }

    if (error) {
      return Promise.reject(error)
    }
  }
}
