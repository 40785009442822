<template>
  <section
    :class="['player', {
      'player_only': isOnlyPlatformLandingType,
      'player_min': isMinLandingType
    }]"
  >
    <default-layout-section class="player__wrapper">
      <div
        v-if="isShowTitle"
        class="player__content"
      >
        <div class="player__top">
          <h2 class="player__title g-h2">
            {{ title }}
          </h2>
          <app-tabs
            v-if="isShowTabs"
            class="player__tabs"
            @app-tabs:change="changeTab"
          />
        </div>
      </div>
      <div
        class="player__platform"
        :class="`player__platform_${platformMode}`"
      >
        <template
          v-for="(activity, key) in activityList"
          :key="`platform-${activity.hash}-${key}`"
        >
          <app-platform
            v-if="activity.hash === currentActivity"
            :hash="activity.hash"
            :mode="platformMode"
            :activity-id="activity.id"
          />
        </template>
      </div>
    </default-layout-section>
  </section>
</template>

<script>
import AppTabs from '@/views/components/app-tabs'
import AppPlatform from '@/views/components/app-platform'
import { mapState } from 'vuex'
import { PLATFORM_MODE_PLATFORM, PLATFORM_MODE_PLAYER, PLATFORM_MODE_CHAT } from '@/constants/platform'
import { MIN_LANDING_TYPE, ONLY_PLATFORM_TYPE, OFFLINE_VIEWER_TYPE } from '@/constants'
import configurations from '@/configurations'

export default {
  name: 'Player',
  components: { AppTabs, AppPlatform },
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      currentActivity: null
    }
  },
  computed: {
    ...mapState(['activities', 'openedActivity', 'isOnlyChat']),
    platformMode () {
      if (process.env.VUE_APP_VIEWER_TYPE === OFFLINE_VIEWER_TYPE || this.isOnlyChat) return PLATFORM_MODE_CHAT

      return process.env.VUE_APP_LANDING_TYPE === MIN_LANDING_TYPE ? PLATFORM_MODE_PLAYER : PLATFORM_MODE_PLATFORM
    },
    isShowTitle () {
      return configurations.view.main.player.isShowTitle && this.title
    },
    isOnlyPlatformLandingType () {
      return process.env.VUE_APP_LANDING_TYPE === ONLY_PLATFORM_TYPE
    },
    isMinLandingType () {
      return process.env.VUE_APP_LANDING_TYPE === MIN_LANDING_TYPE
    },
    activityList () {
      return this.activities.reduce((arr, { items }) => {
        return [...arr, ...items]
      }, [])
    },
    isShowTabs () {
      return this.activities.length >= 2 || this.activities[0].items.length >= 2
    }
  },
  watch: {
    openedActivity: {
      handler (newValue) {
        if (newValue) {
          this.currentActivity = newValue
        }
      },
      immediate: true
    }
  },
  methods: {
    changeTab ({ hash }) {
      this.currentActivity = hash
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/variables";

.player {
  border-bottom: 1px solid var(--divider-backgound);

  &_only {
    border: none;
  }

  &_min {
    padding-bottom: 24px;
    border: none;
  }

  @media #{$lg} {
    padding-bottom: 80px;

    &_only {
      padding-bottom: 0;
    }

    &_min {
      padding-bottom: 100px;
    }
  }

  &__wrapper {
    position: relative;
  }

  &__content {
    position: relative;
    z-index: 1;
    padding: 40px 16px 0;

    @media #{$md} {
      padding: 40px 24px 0;
    }

    @media #{$lg} {
      padding: 40px 80px 0;
    }

    @media #{$xl} {
      padding: 40px 100px 0;
    }
  }

  &__top {
    @media #{$lg} {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;
      row-gap: 40px;
    }
  }

  &__title {
    margin: 0 0 40px 0;

    @media #{$lg} {
      margin-right: 16px;
      margin-bottom: 0;
      flex-shrink: 0;
    }
  }

  &__tabs {
    margin-bottom: 40px;

    @media #{$lg} {
      flex-shrink: 0;
      margin-bottom: 0;
    }
  }

  &__platform {
    min-height: 584px;

    &_platform {
      min-height: 640px;
    }

    @media #{$sm} {
      min-height: 624px;

      &_platform {
        min-height: 680px;
      }
    }

    @media #{$md} {
      min-height: 836px;

      &_platform {
        min-height: 900px;
      }
    }

    @media #{$platform-lg} {
      min-height: 936px;

      &_platform {
        min-height: 1000px;
      }
    }

    @media #{$lg} {
      min-height: 506px;

      &_platform {
        min-height: 570px;
      }
    }

    &_player {
      position: relative;
      padding-top: 56.25%;
      min-height: auto;
    }
  }
}
</style>
