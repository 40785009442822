<template>
  <div class="app-tabs">
    <div class="app-tabs__wrapper">
      <app-button
        v-for="(tab, key) in tabs"
        :key="key"
        class="app-tabs__tab btn"
        :class="{ 'app-tabs__tab_active': key === currentTab }"
        @click="changeTab(key)"
      >
        <span class="g-truncate-1-line">{{ tab }}</span>
      </app-button>
    </div>
    <vue-multiselect
      v-model="select"
      :options="options"
      class="app-tabs__select"
      label="hallName"
      track-by="hallName"
      :show-labels="false"
      :searchable="false"
      :allow-empty="false"
      @update:model-value="emitChangeEvent"
    >
      <template #singleLabel="{ option }">
        {{ option.hallName }}
      </template>
    </vue-multiselect>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import VueMultiselect from 'vue-multiselect'
import AppButton from '@/views/components/app-button/index.vue'

export default {
  name: 'AppTabs',
  components: { AppButton, VueMultiselect },
  emits: ['app-tabs:change'],
  data () {
    return {
      tabs: [],
      currentTab: null,
      select: null
    }
  },
  computed: {
    ...mapState(['activities', 'openedActivity']),
    options () {
      return this.activities[this.currentTab]?.items || []
    }
  },
  watch: {
    openedActivity: {
      handler (newValue) {
        if (newValue) {
          const currentActivityIdx = this.activities.findIndex(({ items }) => {
            return items.some(({ hash }) => hash === newValue)
          })

          if (currentActivityIdx && currentActivityIdx !== -1) {
            this.currentTab = currentActivityIdx
            this.select = this.activities[currentActivityIdx].items.find(({ hash }) => hash === newValue)
          }
        }
      },
      immediate: true
    }
  },
  created () {
    this.activities.forEach(item => {
      this.tabs.push(item.tabName)
    })

    this.currentTab = 0
    this.select = this.activities[0].items[0]
  },
  methods: {
    changeTab (tabIdx) {
      if (this.currentTab === tabIdx) return

      this.currentTab = tabIdx
      this.select = this.activities[this.currentTab]?.items[0]
      this.emitChangeEvent()
    },
    emitChangeEvent () {
      this.$emit('app-tabs:change', { ...this.select })
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/variables";

.app-tabs {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 100%;

  @media #{$md} {
    flex-direction: row;
    gap: 24px;
  }

  &__wrapper {
    display: flex;

    @media #{$md} {
      flex-direction: row;
    }
  }

  &__tab {
    padding: 4px 20px;
    font-size: 14px;
    line-height: 34px;
    color: var(--tab-text-color);
    text-transform: uppercase;
    letter-spacing: 1.3px;
    background-color: var(--tab-background);
    border: 1px solid var(--active-tab-background);
    transition: background-color 0.2s ease, color 0.2s ease;
    flex-grow: 1;

    &_active {
      color: var(--active-tab-text-color);
      background-color: var(--active-tab-background);
    }

    @media #{$md} {
      min-width: 174px;
      flex-grow: 0;
    }

    @media #{$lg} {
      min-width: auto;
    }
  }

  &__select {
    @media #{$md} {
      flex-grow: 1;
    }

    @media #{$lg} {
      min-width: 260px;
      width: auto;
      flex-grow: 0;
    }
  }
}
</style>
