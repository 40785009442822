import { api } from './api'
import to from 'await-to-js'

export default {
  async getActivity ({ rootState }, hash) {
    const requestData = {
      hash,
      config: {
        params: {}
      }
    }

    if (rootState.isOnlyChat) {
      requestData.config.params['is-only-chat'] = true
    }

    const [error, response] = await to(api.getActivity(requestData))

    if (response) {
      return Promise.resolve(response)
    }

    if (error) {
      return Promise.reject(error)
    }
  }
}
