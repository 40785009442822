import { SM_BREAKPOINT, MD_BREAKPOINT, LG_BREAKPOINT, XL_BREAKPOINT } from '@/constants/breakpoints'

export default {
  data () {
    return {
      xs: window.matchMedia(`only screen and (max-width: ${SM_BREAKPOINT - 1}px)`).matches,
      sm: window.matchMedia(`only screen and (min-width: ${SM_BREAKPOINT}px) and (max-width: ${MD_BREAKPOINT - 1}px)`).matches,
      md: window.matchMedia(`only screen and (min-width: ${MD_BREAKPOINT}px) and (max-width: ${LG_BREAKPOINT - 1}px)`).matches,
      lg: window.matchMedia(`only screen and (min-width: ${LG_BREAKPOINT}px) and (max-width: ${XL_BREAKPOINT - 1}px)`).matches,
      xl: window.matchMedia(`only screen and (min-width: ${XL_BREAKPOINT}px)`).matches,
      smAndUp: window.matchMedia(`only screen and (min-width: ${SM_BREAKPOINT}px)`).matches,
      mdAndUp: window.matchMedia(`only screen and (min-width: ${MD_BREAKPOINT}px)`).matches,
      lgAndUp: window.matchMedia(`only screen and (min-width: ${LG_BREAKPOINT}px)`).matches
    }
  },
  computed: {
    $isXS: {
      get () {
        return this.xs
      },
      set (bool) {
        this.xs = bool
      }
    },
    $isSM: {
      get () {
        return this.sm
      },
      set (bool) {
        this.sm = bool
      }
    },
    $isMD: {
      get () {
        return this.md
      },
      set (bool) {
        this.md = bool
      }
    },
    $isLG: {
      get () {
        return this.lg
      },
      set (bool) {
        this.lg = bool
      }
    },
    $isXL: {
      get () {
        return this.xl
      },
      set (bool) {
        this.xl = bool
      }
    },
    $smAndUp: {
      get () {
        return this.smAndUp
      },
      set (bool) {
        this.smAndUp = bool
      }
    },
    $mdAndUp: {
      get () {
        return this.mdAndUp
      },
      set (bool) {
        this.mdAndUp = bool
      }
    },
    $lgAndUp: {
      get () {
        return this.lgAndUp
      },
      set (bool) {
        this.lgAndUp = bool
      }
    }
  },
  beforeMount () {
    window.addEventListener('resize', this.updateBreakpoints)
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.updateBreakpoints)
  },
  methods: {
    updateBreakpoints () {
      this.$isXS = window.matchMedia(`only screen and (max-width: ${SM_BREAKPOINT - 1}px)`).matches
      this.$isSM = window.matchMedia(`only screen and (min-width: ${SM_BREAKPOINT}px) and (max-width: ${MD_BREAKPOINT - 1}px)`).matches
      this.$isMD = window.matchMedia(`only screen and (min-width: ${MD_BREAKPOINT}px) and (max-width: ${LG_BREAKPOINT - 1}px)`).matches
      this.$isLG = window.matchMedia(`only screen and (min-width: ${LG_BREAKPOINT}px) and (max-width: ${XL_BREAKPOINT - 1}px)`).matches
      this.$isXL = window.matchMedia(`only screen and (min-width: ${XL_BREAKPOINT}px)`).matches
      this.$smAndUp = window.matchMedia(`only screen and (min-width: ${SM_BREAKPOINT}px)`).matches
      this.$mdAndUp = window.matchMedia(`only screen and (min-width: ${MD_BREAKPOINT}px)`).matches
      this.$lgAndUp = window.matchMedia(`only screen and (min-width: ${LG_BREAKPOINT}px)`).matches
    }
  }
}
