import { MAX_LANDING_TYPE, MID_LANDING_TYPE, MIN_LANDING_TYPE, ONLY_PLATFORM_TYPE } from '@/constants'
import maxViewConfiguration from '@/configurations/view/mashroom/max'
import midViewConfiguration from '@/configurations/view/mashroom/mid'
import minViewConfiguration from '@/configurations/view/mashroom/min'
import onlyPlatformViewConfiguration from '@/configurations/view/mashroom/only-platform'

let viewConfiguration = null

switch (process.env.VUE_APP_LANDING_TYPE) {
  case MAX_LANDING_TYPE:
    viewConfiguration = maxViewConfiguration
    break
  case MID_LANDING_TYPE:
    viewConfiguration = midViewConfiguration
    break
  case MIN_LANDING_TYPE:
    viewConfiguration = minViewConfiguration
    break
  case ONLY_PLATFORM_TYPE:
    viewConfiguration = onlyPlatformViewConfiguration
    break
  default:
    viewConfiguration = minViewConfiguration
}

export default viewConfiguration
