/**
 * Значение get параметра для dev стенда
 * @type {string}
 */
export const DEV_STAND = 'dev'
/**
 * Значение get параметра для demo стенда
 * @type {string}
 */
export const DEMO_STAND = 'demo'
/**
 * Значение get параметра для test стенда
 * @type {string}
 */
export const TEST_STAND = 'test'
/**
 * Значение get параметра для prod стенда
 * @type {string}
 */
export const PROD_STAND = 'prod'
/**
 * Значение get параметра для локального стенда
 * @type {string}
 */
export const LOCAL_STAND = 'local'
/**
 * Значение get параметра для dev стенда ВТБ
 * @type {string}
 */
export const DEV_VTB_STAND = 'dev-vtb'
/**
 * Значение get параметра для demo стенда ВТБ
 * @type {string}
 */
export const DEMO_VTB_STAND = 'demo-vtb'
/**
 * Значение get параметра для prod стенда ВТБ
 * @type {string}
 */
export const PROD_VTB_STAND = 'prod-vtb'
/**
 * Список стендов
 * @type {string[]}
 */
export const STANDS = [DEV_STAND, DEMO_STAND, TEST_STAND, PROD_STAND]
/**
 * Объект с сылками для каждого стенда платформы
 * @type {{[p: string]: string}}
 */
export const PLATFORM_STAND_URLS = {
  [DEV_STAND]: 'https://event-dev.pikemedia.live',
  [DEMO_STAND]: 'https://embed-demo-cdn.mashroom.online',
  [TEST_STAND]: 'https://event-test.pikemedia.live',
  [PROD_STAND]: 'https://embed-cdn.mashroom.online',
  [DEV_VTB_STAND]: 'https://event-dev-vtb.pikemedia.live',
  [DEMO_VTB_STAND]: 'https://embed-demo-cdn.wecloud.events',
  [PROD_VTB_STAND]: 'https://embed-cdn.wecloud.events',
  [LOCAL_STAND]: 'http://192.168.1.62:8080'
}
/**
 * Объект с сылками на API для каждого стенда платформы
 * @type {{[p: string]: string}}
 */
export const API_STAND_URLS = {
  [DEV_STAND]: 'https://media-dev.pikemedia.live',
  [DEMO_STAND]: 'https://media-demo.pikemedia.live',
  [TEST_STAND]: 'https://media-test.pikemedia.live',
  [PROD_STAND]: 'https://api.mashroom.online',
  [DEV_VTB_STAND]: 'https://media-dev-vtb.pikemedia.live',
  [DEMO_VTB_STAND]: 'https://api-demo.wecloud.events',
  [PROD_VTB_STAND]: 'https://api.wecloud.events',
  [LOCAL_STAND]: 'https://media-dev.pikemedia.live'
}
