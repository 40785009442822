<template>
  <nav
    class="app-navigation"
    :class="{ 'app-navigation_mobile': isMobileNavigation }"
  >
    <a
      v-for="(link, key) in menuLinks"
      :key="key"
      v-scroll-to="`${link.href}`"
      :href="link.href"
      class="app-navigation__link"
      @click="$emit('navigation:click')"
    >
      {{ link.name }}
    </a>
    <!-- Пустой элемент для автоматического равного отступа до кнопки -->
    <span v-show="isMainNavigationWithMobileMenu && fullMenuLinks.length > 3" />
  </nav>
</template>

<script>
import configurations from '@/configurations'
import { mapGetters } from 'vuex'

export default {
  name: 'AppNavigation',
  props: {
    isMobileNavigation: {
      type: Boolean,
      default: false
    }
  },
  emits: ['navigation:click', 'navigation:menu-links'],
  computed: {
    ...mapGetters(['isHasActivities']),
    isMainNavigationWithMobileMenu () {
      return !this.isMobileNavigation && this.$lgAndUp && !this.$isXL
    },
    isHasPartners () {
      return configurations.view.main.partners.isShow && configurations.content.partners.items && configurations.content.partners.items.length
    },
    isHasSpeakers () {
      return configurations.view.main.speakers.isShow && configurations.content.speakers.items && configurations.content.speakers.items.length
    },
    isShowSchedule () {
      return configurations.view.main.schedule.isShow && this.isHasActivities
    },
    isShowImageBlock () {
      return configurations.view.main.imageBlock.isShow &&
              configurations.content.imageBlock &&
              configurations.content.imageBlock.title &&
              configurations.content.imageBlock.image
    },
    isShowInfo () {
      return configurations.view.main.info.isShow &&
              configurations.content.info &&
              configurations.content.info.title &&
              configurations.content.info.items?.length
    },
    fullMenuLinks () {
      return [
        ...this.withPlayer,
        ...this.withSchedule,
        ...this.withSpeakers,
        ...this.withImageBlock,
        ...this.withInfo,
        ...this.withPartners
      ]
    },
    menuLinks () {
      this.$emit('navigation:menu-links', this.fullMenuLinks.length)

      if (this.isMainNavigationWithMobileMenu && this.fullMenuLinks.length > 3) {
        return this.fullMenuLinks.slice(0, 3)
      }

      return this.fullMenuLinks
    },
    withPlayer () {
      return this.isHasActivities ? [
        {
          name: configurations.content.player.title,
          href: '#player'
        }
      ] : []
    },
    withSchedule () {
      return this.isShowSchedule ? [
        {
          name: configurations.content.schedule.title,
          href: '#schedule'
        }
      ] : []
    },
    withSpeakers () {
      return this.isHasSpeakers ? [
        {
          name: configurations.content.speakers.title,
          href: '#speakers'
        }
      ] : []
    },
    withPartners () {
      return this.isHasPartners ? [
        {
          name: configurations.content.partners.title,
          href: '#partners'
        }
      ] : []
    },
    withImageBlock () {
      return this.isShowImageBlock ? [
        {
          name: configurations.content.imageBlock.title,
          href: '#image-block'
        }
      ] : []
    },
    withInfo () {
      return this.isShowInfo ? [
        {
          name: configurations.content.info.title,
          href: '#info'
        }
      ] : []
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/variables";

.app-navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 50px;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.4;
  color: var(--primary-text-color);

  &_mobile {
    flex-direction: column;
    align-items: center;
    gap: 40px;
    font-size: 32px;
    font-weight: 300;
    line-height: 1.25;
    color: var(--menu-text-color);

    @media #{$md} {
      align-items: end;
    }
  }

  &__link {
    max-width: 12ch;
    color: inherit;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
