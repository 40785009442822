/**
  * Получение скрипта Яндекс.Метрики со встроенным номером счётчика
  * @param {string} platformUrl - URL-платформы
  * @return {string} JS код счётчика
  */
export const getYandexMetricaScript = (platformUrl) => {
  return `(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
  m[i].l=1*new Date();
  for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
  k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
  (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

  ym(${process.env.VUE_APP_YANDEX_METRIKA}, "init", {
      trustedDomains:['${platformUrl}'],
      clickmap:true,
      trackLinks:true,
      accurateTrackBounce:true,
      webvisor:true
  });`
}

/**
 * Возвращает формат времени в виде - "18:07"
 * @param {string} RFCDate - в формате RFC 3339
 * @param {object} options - формат даты
 * @returns {string}
 */
export function getTimeFromDate (RFCDate, options = { hour: 'numeric', minute: 'numeric' }) {
  const date = new Date(RFCDate)

  return new Intl.DateTimeFormat('ru-RU', options).format(date)
}
