export default {
  header: {
    isShow: true,
    isShowMenu: true
  },
  main: {
    isShowBackground: false,
    isShowPageBackgroundImage: false,
    aboutActivity: {
      isShow: true,
      isShowBorder: true
    },
    player: {
      isShowTitle: true
    },
    schedule: {
      isShow: true
    },
    speakers: {
      isShow: true
    },
    imageBlock: {
      isShow: false
    },
    info: {
      isShow: false
    },
    partners: {
      isShow: false
    }
  },
  footer: {
    isShow: true,
    isShowLabel: false
  }
}
