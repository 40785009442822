<template>
  <section class="schedule-event-card">
    <div class="schedule-event-card__wrapper">
      <time class="schedule-event-card__time schedule-event-card__time_before">{{ time }}</time>
      <div
        class="schedule-event-card__info"
        :class="{
          'schedule-event-card__info_past': isPastEvent,
          'schedule-event-card__info_live': isLiveEvent,
          'schedule-event-card__info_future': isFutureEvent
        }"
      >
        <p
          v-if="speakers"
          class="schedule-event-card__speakers"
        >
          {{ speakers }}
        </p>
        <h3 class="schedule-event-card__name">
          {{ event.name }}
        </h3>
        <span
          class="schedule-event-card__status-point"
          :class="{
            'schedule-event-card__status-point_past': isPastEvent,
            'schedule-event-card__status-point_live': isLiveEvent,
            'schedule-event-card__status-point_future': isFutureEvent
          }"
        />
      </div>
      <div class="schedule-event-card__actions">
        <time class="schedule-event-card__time schedule-event-card__time_after">{{ time }}</time>
        <button
          v-if="isHasMoreInfo"
          class="schedule-event-card__more-info-btn btn"
          :class="{ 'schedule-event-card__more-info-btn_active': isShowMoreInfo }"
          @click="isShowMoreInfo = !isShowMoreInfo"
        >
          <span />
        </button>
      </div>
    </div>
    <div
      v-if="isHasMoreInfo"
      ref="moreInfo"
      class="schedule-event-card__more-info"
    >
      <div
        ref="moreInfoWrapper"
        class="schedule-event-card__more-info-wrapper"
      >
        <div
          v-if="isHasLinks"
          class="schedule-event-card__links"
        >
          <template v-if="event.materials.length">
            <a
              v-for="(material, materialKey) in event.materials"
              :key="`materialLink-${materialKey}`"
              class="schedule-event-card__link"
              :href="material.url"
              target="_blank"
              download
            >
              <mdicon
                name="paperclip"
                class="schedule-event-card__icon"
              />
              {{ material.name }}
            </a>
          </template>
          <template v-if="event.links.length">
            <a
              v-for="(link, linkKey) in event.links"
              :key="`eventLink-${linkKey}`"
              class="schedule-event-card__link"
              :href="link.url"
              target="_blank"
            >
              <mdicon
                name="web"
                class="schedule-event-card__icon"
              />
              {{ link.title }}
            </a>
          </template>
        </div>
        <p
          v-if="event.description"
          class="schedule-event-card__description"
        >
          {{ event.description }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import { getTimeFromDate } from '@/helpers'
import { ARCHIVE_EVENT_STATUS, LIVE_EVENT_STATUS, FUTURE_EVENT_STATUS } from '@/constants/schedule'

export default {
  name: 'ScheduleEventCardComp',
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isShowMoreInfo: false
    }
  },
  computed: {
    time () {
      return getTimeFromDate(this.event.eventStart)
    },
    isFutureEvent () {
      return this.event.status === FUTURE_EVENT_STATUS
    },
    isLiveEvent () {
      return this.event.status === LIVE_EVENT_STATUS
    },
    isPastEvent () {
      return this.event.status === ARCHIVE_EVENT_STATUS
    },
    speakers () {
      if (!this.event.speakers || !this.event.speakers.length) return ''

      return this.event.speakers.map(({ lastName, firstName, middleName }) => {
        let speaker = lastName

        if (firstName) {
          speaker = `${speaker} ${firstName.substring(0, 1)}.`

          if (middleName) {
            speaker = `${speaker}${middleName.substring(0, 1)}.`
          }
        }

        return speaker
      }).join(', ')
    },
    isHasLinks () {
      return Boolean(this.event.links.length) || Boolean(this.event.materials.length)
    },
    isHasMoreInfo () {
      return this.event.description || this.isHasLinks
    }
  },
  watch: {
    isShowMoreInfo () {
      this.changeMoreInfoHeight()
    }
  },
  methods: {
    changeMoreInfoHeight () {
      const moreInfoBlock = this.$refs.moreInfo
      const moreInfoWrapper = this.$refs.moreInfoWrapper

      if (this.isShowMoreInfo) {
        moreInfoBlock.style.height = `${moreInfoWrapper.offsetHeight}px`
      } else {
        moreInfoBlock.style.height = 0
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/variables.scss';

.schedule-event-card {
  position: relative;
  padding-left: 32px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  @media #{$md} {
    padding-left: 0;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media #{$md} {
      flex-direction: row;
      align-items: center;
    }
  }

  &__time {
    margin-left: 16px;
    line-height: 24px;

    &_before {
      display: none;
    }

    &_after {
      margin-right: 8px;
    }

    @media #{$md} {
      margin-right: 64px;
      margin-left: 0;
      width: 50px;
      flex-shrink: 0;

      &_before {
        display: block;
      }

      &_after {
        display: none;
      }
    }
  }

  &__info {
    position: relative;
    margin-bottom: 16px;
    padding: 12px 16px;
    background-color: var(--page-background);
    border: 1px solid var(--active-tab-background);

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: -20px;
      border: 10px solid;
      border-color: transparent var(--active-tab-background) transparent transparent;
      transform: translateY(-50%);
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: -17.5px;
      border: 9px solid;
      border-color: transparent var(--page-background) transparent transparent;
      transform: translateY(-50%);
    }

    @media #{$md} {
      margin-right: 24px;
      margin-bottom: 0;
      padding: 12px 24px;
    }
  }

  &__speakers {
    margin-top: 0;
    margin-bottom: 8px;
    color: var(--secondary-text-color);
  }

  &__name {
    margin: 0;
    font: inherit;
    word-break: break-word;
  }

  &__status-point {
    position: absolute;
    top: 50%;
    left: -33px;
    padding: 1px;
    width: 16px;
    height: 16px;
    background-color: currentColor;
    background-clip: content-box;
    border: 1px solid currentColor;
    border-radius: 50%;
    transform: translateY(-50%);
    box-sizing: border-box;

    &_future {
      color: var(--event-future-status-color);
    }

    &_live {
      color: var(--event-live-status-color);
    }

    &_past {
      color: var(--event-past-status-color);
    }

    @media #{$md} {
      left: -41px;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }

  &__more-info-btn {
    position: relative;
    width: 24px;
    height: 24px;
    cursor: pointer;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 18px;
      height: 18px;
      color: var(--button-color);
      border: 2px solid currentColor;
      border-radius: 50%;
      transform: translate(-50%, -50%);

      &:before,
      &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 2px;
        height: 10px;
        background-color: currentColor;
        transition: 0.3s ease-in-out;
        content: '';
      }

      &:before {
        transform: translate(-50%, -50%);
      }

      &:after {
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }

    &_active span:before {
      transform: translate(-50%, -50%) rotate(-90deg);
    }
  }

  &__more-info {
    height: 0;
    transition: height 0.5s ease;
    will-change: height;
    overflow: hidden;

    @media #{$md} {
      padding-left: 114px;
    }
  }

  &__more-info-wrapper {
    padding-top: 16px;
  }

  &__description {
    margin: 0;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    white-space: pre-line;
    word-break: break-word;
    overflow-wrap: break-word;
  }

  &__links {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
    gap: 12px;
  }

  &__link {
    display: inline-flex;
    align-items: center;
    padding: 8px 20px 8px 12px;
    background-color: var(--chip-background);
  }

  &__icon {
    margin-right: 8px;
    color: var(--icon-color);
  }
}
</style>
