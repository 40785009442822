<template>
  <div
    :id="getId"
    :iframe-name="getIframeName"
    :src="getUrl"
    :mode="mode"
    :activity-id="activityId"
  />
</template>

<script>
import {
  PLATFORM_MODE_PLATFORM,
  PLATFORM_MODE_PLAYER,
  PLATFORM_MODE_SCHEDULE,
  PLATFORM_MODE_CHAT
} from '@/constants/platform'
import { PLATFORM_STAND_URLS } from '@/constants/stands'
import { mapState } from 'vuex'

export default {
  name: 'AppPlatform',
  props: {
    hash: {
      type: String,
      required: true
    },
    activityId: {
      type: Number,
      default: null
    },
    mode: {
      type: String,
      default: PLATFORM_MODE_PLATFORM // platform | player | shedule | chat
    }
  },
  computed: {
    ...mapState(['stand', 'code', 'customDomain']),
    getId () {
      return `pml-iframe-container-${this.hash}-${this.mode}`
    },
    getIframeName () {
      return `pml-iframe-${this.hash}-${this.mode}`
    },
    getUrl () {
      const url = new URL(PLATFORM_STAND_URLS[this.stand])

      url.searchParams.set('hash', this.hash)

      if (process.env.VUE_APP_PLATFORM_THEME) {
        url.searchParams.set('theme', process.env.VUE_APP_PLATFORM_THEME)
      }

      switch (this.mode) {
        case PLATFORM_MODE_SCHEDULE:
          url.searchParams.set('is-only-schedule', 'true')
          break
        case PLATFORM_MODE_PLAYER:
          url.searchParams.set('is-embedded-player', 'true')
          break
        case PLATFORM_MODE_CHAT:
          url.searchParams.set('is-only-chat', 'true')
          break
      }

      if (this.code && (this.mode === PLATFORM_MODE_PLATFORM || this.mode === PLATFORM_MODE_CHAT)) {
        url.searchParams.set('code', this.code)
      }

      if (this.customDomain && (this.mode === PLATFORM_MODE_PLATFORM || this.mode === PLATFORM_MODE_PLAYER)) {
        url.searchParams.set('custom-domain', this.customDomain)
      }

      return url.toString()
    }
  },
  mounted () {
    if (window.initIframe) return window.initIframe(this.getId)

    const intervalId = setInterval(() => {
      if (window.initIframe) {
        clearInterval(intervalId)
        window.initIframe(this.getId)
      }
    }, 100)
  }
}
</script>
