<template>
  <section :class="['about-activity', { 'about-activity_with-border': isShowBorder }]">
    <default-layout-section class="about-activity__wrapper">
      <template v-if="!isOnlyTitle">
        <div
          class="about-activity__content about-activity__content_date"
        >
          <time class="about-activity__date">{{ date }}</time>
          <time class="about-activity__time">{{ time }}</time>
        </div>
        <hr class="about-activity__divider">
      </template>
      <div class="about-activity__content">
        <h1 class="about-activity__name g-h1">
          {{ name }}
        </h1>
        <div
          v-if="!isOnlyTitle"
          class="about-activity__description"
        >
          <p
            v-for="(item, key) in description"
            :key="key"
            class="about-activity__paragraph"
            v-html="item"
          />
        </div>
      </div>
    </default-layout-section>
  </section>
</template>

<script>
import configurations from '@/configurations'

export default {
  name: 'AboutActivity',
  props: {
    isOnlyTitle: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isShowBorder: configurations.view.main.aboutActivity.isShowBorder,
      name: configurations.content.aboutActivity.name,
      description: configurations.content.aboutActivity.description,
      date: configurations.content.aboutActivity.date,
      time: configurations.content.aboutActivity.time
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/variables";

.about-activity {
  &_with-border {
    border-bottom: 1px solid var(--divider-backgound);
  }

  &__wrapper {
    position: relative;
  }

  &__content {
    position: relative;
    z-index: 1;
    padding: 24px 16px 40px;

    &_date {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 12px;
      padding-top: 40px;
    }

    @media #{$md} {
      padding: 40px 24px;

      &_date {
        flex-direction: row;
        justify-content: start;
        gap: 80px;
      }
    }

    @media #{$lg} {
      padding: 40px 80px;
    }

    @media #{$xl} {
      padding: 40px 100px;
    }
  }

  &__date,
  &__time {
    font-size: 30px;
    font-weight: 300;
    line-height: 1.3;
    letter-spacing: 5px;
  }

  &__divider {
    position: relative;
    left: 50%;
    margin: 0;
    width: 100vw;
    height: 1px;
    background: var(--divider-backgound);
    border: none;
    transform: translateX(-50%);
  }

  &__name {
    margin: 0;
  }

  &__description {
    margin-top: 40px;
  }

  &__paragraph {
    margin: 0;
    white-space: pre-line;
    word-break: break-word;
    overflow-wrap: break-word;
  }

  &__paragraph + &__paragraph {
    margin-top: 20px;
  }
}
</style>
