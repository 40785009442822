<template>
  <div
    class="app"
    :style="customBackgroundImageStyle"
  >
    <app-header
      v-if="isShowHeader"
      class="app__header"
      :is-show-menu="isShowMenu"
      :is-static="isShowBackgroundImage"
    />
    <main
      class="app__content"
      :class="{ 'app__content_only': isOnlyPlatformLandingType || isShowBackgroundImage }"
    >
      <about-activity
        v-if="isShowAboutActivity"
        :is-only-title="isMinLandingType"
      />
      <player
        v-if="isHasActivities"
        id="player"
        v-bind="player"
      />
      <schedule
        v-if="isShowSchedule"
        id="schedule"
        v-bind="schedule"
      />
      <speakers
        v-if="isShowSpeakers"
        id="speakers"
        v-bind="speakers"
      />
      <image-block
        v-if="isShowImageBlock"
        id="image-block"
        v-bind="imageBlock"
      />
      <info
        v-if="isShowInfo"
        id="info"
        v-bind="info"
      />
      <partners
        v-if="isShowPartners"
        id="partners"
        v-bind="partners"
      />
    </main>
    <app-footer
      v-if="isShowFooter"
      class="app__footer"
      :is-show-label="isShowLabel"
    />
    <div
      class="app__overlay"
      :class="overlayActiveClass"
    />
    <app-mobile-menu v-if="isShowMenu" />
  </div>
</template>

<script>
import AppHeader from '@/views/components/app-header'
import AppFooter from '@/views/components/app-footer'
import AboutActivity from '@/views/blocks/about-activity'
import Partners from '@/views/blocks/partners'
import Player from '@/views/blocks/player'
import Schedule from '@/views/blocks/schedule'
import Speakers from '@/views/blocks/speakers'
import Info from '@/views/blocks/info'
import ImageBlock from '@/views/blocks/image-block'
import AppMobileMenu from '@/views/components/app-mobile-menu'
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import { STANDS, PLATFORM_STAND_URLS } from '@/constants/stands'
import configurations from '@/configurations'
import { ONLY_PLATFORM_TYPE, MIN_LANDING_TYPE } from '@/constants'
import { DEFAULT_LANDING_THEME, LANDING_THEMES } from '@/constants/themes'
import { getYandexMetricaScript } from '@/helpers'

const ACTIVITY_STATUS_CURRENT = 1
const ACTIVITY_STATUS_PLANNED = 2
const ACTIVITY_STATUS_READY = 3

export default {
  name: 'App',
  components: {
    AppFooter,
    AppHeader,
    AboutActivity,
    Partners,
    Player,
    Schedule,
    Speakers,
    Info,
    ImageBlock,
    AppMobileMenu
  },
  data () {
    return {
      activityName: configurations.content.aboutActivity.name,
      player: configurations.content.player,
      schedule: configurations.content.schedule,
      speakers: configurations.content.speakers,
      info: configurations.content.info,
      imageBlock: configurations.content.imageBlock,
      partners: configurations.content.partners
    }
  },
  computed: {
    ...mapGetters(['isHasActivities']),
    ...mapState(['isMenuOpen', 'activities', 'stand']),
    overlayActiveClass () {
      return this.isMenuOpen ? 'app__overlay_active' : ''
    },
    isHasAboutActivity () {
      return Boolean(configurations.content.aboutActivity)
    },
    isHasPartners () {
      return this.partners && this.partners.items?.length
    },
    isHasSpeakers () {
      return this.speakers && this.speakers.items?.length
    },
    isHasImageBlock () {
      return this.imageBlock && this.imageBlock.image && this.imageBlock.items?.length
    },
    isHasInfo () {
      return this.info && this.info.items?.length
    },
    isShowHeader () {
      return configurations.view.header.isShow
    },
    isShowMenu () {
      return configurations.view.header.isShowMenu
    },
    isShowAboutActivity () {
      return configurations.view.main.aboutActivity.isShow && this.isHasAboutActivity
    },
    isShowSchedule () {
      return configurations.view.main.schedule.isShow && this.isHasActivities
    },
    isShowSpeakers () {
      return configurations.view.main.speakers.isShow && this.isHasSpeakers
    },
    isShowInfo () {
      return configurations.view.main.info.isShow && this.isHasInfo
    },
    isShowImageBlock () {
      return configurations.view.main.imageBlock.isShow && this.isHasImageBlock
    },
    isShowPartners () {
      return configurations.view.main.partners.isShow && this.isHasPartners
    },
    isShowFooter () {
      return configurations.view.footer.isShow
    },
    isShowLabel () {
      return configurations.view.footer.isShowLabel
    },
    isShowBackgroundImage () {
      return process.env.VUE_APP_LANDING_BACKGROUND_IMAGE === 'on' && configurations.content.pageBackgroundImage && configurations.view.main.isShowPageBackgroundImage
    },
    isMinLandingType () {
      return process.env.VUE_APP_LANDING_TYPE === MIN_LANDING_TYPE
    },
    isOnlyPlatformLandingType () {
      return process.env.VUE_APP_LANDING_TYPE === ONLY_PLATFORM_TYPE
    },
    customBackgroundImageStyle () {
      return this.isShowBackgroundImage ? {
        backgroundImage: `url("${configurations.content.pageBackgroundImage}")`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      } : {}
    }
  },
  watch: {
    isMenuOpen (bool) {
      if (bool) {
        return document.body.classList.add('g-overflow-hidden')
      }

      document.body.classList.remove('g-overflow-hidden')
    }
  },
  beforeMount () {
    const url = new URL(location.href)
    const stand = url.searchParams.get('stand')
    const code = url.searchParams.get('code')
    const customDomain = url.searchParams.get('custom-domain')
    const isOnlyChat = url.searchParams.get('is-only-chat')
    const pageTitle = configurations.content.pageTitle

    if (pageTitle) {
      document.title = pageTitle
    }

    // добавляем класс на body для переключения темы
    if (LANDING_THEMES.includes(process.env.VUE_APP_LANDING_THEME) && process.env.VUE_APP_LANDING_THEME !== DEFAULT_LANDING_THEME) {
      document.body.classList.add(process.env.VUE_APP_LANDING_THEME)
    }

    switch (true) {
      case Boolean(configurations.content.activities && configurations.content.activities.length):
        this.setActivities([...configurations.content.activities].splice(0, 2))
        break
      default:
        this.setActivities([{
          tabName: new Intl.DateTimeFormat('ru-RU').format(new Date()),
          items: [{
            hash: 'TestPage',
            id: null
          }]
        }])
    }

    const isHasAnyActivities = this.activities.length > 1 || this.activities[0].items.length > 1

    if (
      process.env.VUE_APP_AUTO_OPEN_ACTUAL_ACTIVITY === 'on' &&
      isHasAnyActivities
    ) {
      this.openCurrentActivity()
    } else {
      this.setOpenedActivity(this.activities[0].items[0].hash)
    }

    if (stand && STANDS.includes(stand)) {
      this.setStand(stand)
    }

    if (code) {
      this.setCode(code)
    }

    if (customDomain || process.env.VUE_APP_CUSTOM_DOMAIN) {
      const domain = customDomain || process.env.VUE_APP_CUSTOM_DOMAIN

      if (domain) this.setCustomDomain(domain)
    }

    if (isOnlyChat) {
      this.setIsOnlyChat(true)
    }

    this.connectPlatform()
    this.connectAnalytics()
  },
  methods: {
    ...mapMutations(['setActivities', 'setStand', 'setCode', 'setCustomDomain', 'setOpenedActivity', 'setIsOnlyChat']),
    ...mapActions('activity', ['getActivity']),
    /**
     * Создаем script для подключения скрипта платформы
     * @returns {HTMLScriptElement}
     */
    createPlatformScript () {
      const script = document.createElement('script')

      script.src = `${PLATFORM_STAND_URLS[this.stand]}/iframe/js/index.js`

      return script
    },
    /**
     * Подключаем платформу
     */
    connectPlatform () {
      document.head.append(this.createPlatformScript())
    },
    /**
     * Переключает вкладки на наиболее актуальное мероприятие
     */
    async openCurrentActivity () {
      const activityRequests = this.activities.reduce((arr, { items }) => {
        return [...arr, ...items.map(({ hash }) => hash)]
      }, []).map((hash) => this.getActivity(hash))

      const responses = await Promise.allSettled(activityRequests)

      const activitiesList = responses.reduce((result, current) => {
        if (current.status === 'fulfilled') return [...result, current.value.data]

        return result
      }, [])

      const currentActivity = activitiesList.find(({ activity }) =>
        [ACTIVITY_STATUS_CURRENT, ACTIVITY_STATUS_READY, ACTIVITY_STATUS_PLANNED].includes(activity.status)
      )?.activity

      if (currentActivity) {
        const currentActivityHash = this.activities.flatMap((tab) => tab.items).find((item) => item.id === currentActivity.activityID)?.hash

        this.setOpenedActivity(currentActivityHash)
      } else {
        this.setOpenedActivity(this.activities[0].items[0].hash)
      }
    },
    /**
     * Создаем скрипт метрики
     * @returns {HTMLScriptElement}
     */
    createAnalyticsScript () {
      const script = document.createElement('script')

      script.innerHTML = getYandexMetricaScript(PLATFORM_STAND_URLS[this.stand])

      return script
    },
    /**
     * Подключаем скрипт метрику
     */
    connectAnalytics () {
      if (!process.env.VUE_APP_YANDEX_METRIKA) return

      document.head.append(this.createAnalyticsScript())
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/variables";
@import "@/styles/main";

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;

  &__header,
  &__footer {
    flex-shrink: 0;
  }

  &__content {
    flex-grow: 1;
    padding-top: 73px;

    &_only {
      padding-top: 0;
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100vw;
    height: 100vh;
    background-color: var(--overlay-background);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s 0.1s ease-in-out;

    &_active {
      opacity: 1;
      pointer-events: auto;
    }
  }
}
</style>
