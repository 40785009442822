<template>
  <button
    :class="['btn', buttonMod]"
    :aria-label="title"
    @click="$emit('click')"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'AppButton',
  props: {
    buttonType: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  emits: ['click'],
  computed: {
    buttonMod () {
      return this.buttonType ? `btn_${this.buttonType}` : ''
    }
  }
}
</script>

<style lang="scss">
.btn {
  position: relative;
  padding: 0;
  color: inherit;
  background-color: transparent;
  border: none;
  border-radius: 0;
  color: black;
  cursor: pointer;

  &_close {
    width: 24px;
    height: 24px;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 2px;
      height: 20px;
      background-color: currentColor;
    }

    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  &_menu {
    width: 40px;
    height: 40px;

    &:before,
    &:after {
      content: "";
      position: absolute;
      left: 50%;
      width: 6px;
      height: 6px;
      background-color: currentColor;
      border-radius: 50%;
      transform: translateX(-50%);
    }

    &:before {
      top: 6px;
      box-shadow: 0 11px currentColor;
    }

    &:after {
      bottom: 6px;
    }
  }
}
</style>
