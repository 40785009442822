<template>
  <div class="speakers-card">
    <div
      class="speakers-card__photo"
      :class="{'speakers-card__photo_circle': isCirclePhoto}"
    >
      <img
        :src="speaker.photo"
        :alt="speaker.fullName"
        class="speakers-card__image"
      >
    </div>
    <div class="speakers-card__content">
      <span class="speakers-card__name">{{ speaker.fullName }}</span>
      <p
        v-if="speaker.jobTitle"
        class="speakers-card__job-title"
        v-html="speaker.jobTitle"
      />
      <p
        v-if="speaker.description"
        class="speakers-card__description"
        v-html="speaker.description"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SpeakersCardComp',
  props: {
    speaker: {
      type: Object,
      required: true
    },
    shape: {
      type: String,
      default: 'square'
    }
  },
  computed: {
    isCirclePhoto () {
      return this.shape === 'circle'
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/variables.scss";

.speakers-card {
  display: flex;

  @media #{$md} {
    flex-direction: column;
    align-items: center;
  }

  &__photo {
    flex-shrink: 0;
    position: relative;
    margin-right: 16px;
    width: 100px;
    height: 100px;
    overflow: hidden;

    &_circle {
      width: 110px;
      height: 110px;
      border-radius: 100%;
    }

    @media #{$md} {
      margin-right: 0;
      margin-bottom: 16px;
      width: 180px;
      height: 180px;

      &_circle {
        width: 198px;
        height: 198px;
      }
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__content {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 1px;

    @media #{$md} {
      text-align: center;
    }
  }

  &__name {
    text-transform: uppercase;
  }

  &__job-title {
    margin: 16px 0 0;
    color: var(--secondary-text-color);
  }

  &__description {
    margin: 16px 0 0;
    color: var(--tertiary-text-color);
  }
}
</style>
