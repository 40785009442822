/**
 * Статусы прошедшего события расписания
 * @type {number}
 */
export const ARCHIVE_EVENT_STATUS = 0

/**
 * Статусы текущего события расписания
 * @type {number}
 */
export const LIVE_EVENT_STATUS = 1

/**
 * Статусы будущего события расписания
 * @type {number}
 */
export const FUTURE_EVENT_STATUS = 2
