export default [
  {
    fullName: 'Алексей Грабарник',
    jobTitle: 'Исполнительный вице-президент',
    description: 'Газпромбанк',
    photo: require('@/assets/speakers/Алексей Грабарник.jpg')
  },
  {
    fullName: 'Сергей Рогачев',
    jobTitle: 'Генеральный директор',
    description: 'Лидеры изменений',
    photo: require('@/assets/speakers/Сергей Рогачев.png')
  },
  {
    fullName: 'Кирилл Покладов',
    jobTitle: 'ИТ-директор корпоративного и инвестиционного блока',
    description: 'Росбанк',
    photo: require('@/assets/speakers/Кирилл Покладов.jpg')
  },
  {
    fullName: 'Денис Дудоров',
    jobTitle: 'Руководитель Офиса управления продуктом',
    description: 'Avito',
    photo: require('@/assets/speakers/Денис Дудоров.jpg')
  },
  {
    fullName: 'Евгений Михин',
    jobTitle: 'Head of Portfolio Management',
    description: 'Яндекс',
    photo: require('@/assets/speakers/Евгений Михин.jpg')
  },
  {
    fullName: 'Сергей Паршиков',
    jobTitle: 'Руководитель департамента развития цифровых каналов юридических лиц',
    description: 'Альфа-Банк',
    photo: require('@/assets/speakers/Сергей Паршиков.png')
  },
  {
    fullName: 'Екатерина Елманова',
    jobTitle: 'Советник заместителя Председателя Правления',
    description: 'Россельхозбанк',
    photo: require('@/assets/speakers/Екатерина Елманова.jpg')
  },
  {
    fullName: 'Юлия Веневская',
    jobTitle: 'Директор департамента развития цифровых сервисов бизнес-сегмента',
    description: 'Ростелеком',
    photo: require('@/assets/speakers/Юлия Веневская.jpg')
  },
  {
    fullName: 'Анна Аверьянова',
    jobTitle: 'Head of T&D',
    description: 'Робофинанс',
    photo: require('@/assets/speakers/Анна Аверьянова.jpg')
  },
  {
    fullName: 'Тимофей Загуренко',
    jobTitle: 'Директор по информационным технологиям',
    description: 'РН-БашНИПИнефть (Роснефть)',
    photo: require('@/assets/speakers/Тимофей Загуренко.png')
  },
  {
    fullName: 'Марина Кубанина',
    jobTitle: 'Руководитель Центра продуктовой экспертизы',
    description: 'Технологии Доверия (ex-PwC)',
    photo: require('@/assets/speakers/Марина Кубанина.jpg')
  },
  {
    fullName: 'Мария Градова',
    jobTitle: 'Delivery Lead и СРО по инфраструктурным продуктам',
    description: 'Астрал-Софт',
    photo: require('@/assets/speakers/Мария Градова.jpeg')
  },
  {
    fullName: 'Сергей Лебедев',
    jobTitle: 'Руководитель центра экспертизы Lean-Agile (LACE)',
    description: 'YADRO',
    photo: require('@/assets/speakers/Сергей Лебедев.png')
  },
  {
    fullName: 'Максим Матвеев',
    jobTitle: 'Руководитель отдела разработки систем управления ресурсами предприятия (ERP)',
    description: 'YADRO',
    photo: require('@/assets/speakers/Максим Матвеев.jpg')
  },
  {
    fullName: 'Руслан Юсупов',
    jobTitle: 'Управляющий партнер',
    description: 'Лидеры изменений',
    photo: require('@/assets/speakers/Руслан Юсупов.png')
  },
  {
    fullName: 'Дмитрий Баштинский',
    jobTitle: 'Senior Project Manager',
    description: 'HeadHunter',
    photo: require('@/assets/speakers/Дмитрий Баштинский.jpg')
  },
  {
    fullName: 'Наталья Лебедева',
    jobTitle: 'Руководитель практики развития продуктовой экспертизы',
    description: 'Газпром нефть',
    photo: require('@/assets/speakers/Наталья Лебедева.jpg')
  },
  {
    fullName: 'Андрей Гирин',
    jobTitle: 'Управляющий партнер',
    description: 'Лидеры изменений',
    photo: require('@/assets/speakers/Андрей Гирин.png')
  },
  {
    fullName: 'Андрей Макаркин',
    jobTitle: 'Заместитель генерального директора по проектному управлению',
    description: 'Иннохаб Росатома',
    photo: require('@/assets/speakers/Андрей Макаркин.jpg')
  },
  {
    fullName: 'Антон Чижов',
    jobTitle: 'Руководитель Центра Практик Agile',
    description: 'МТС',
    photo: require('@/assets/speakers/Антон Чижов.jpg')
  },
  {
    fullName: 'Александр Рогушкин',
    jobTitle: 'Agile Lead Центра Big Data',
    description: 'МТС',
    photo: require('@/assets/speakers/Александр Рогушкин.jpg')
  },
  {
    fullName: 'Алексей Арефьев',
    jobTitle: 'Директор по развитию продукта и технологий',
    description: 'KION',
    photo: require('@/assets/speakers/Алексей Арефьев.jpeg')
  },
  {
    fullName: 'Лилия Алексеева',
    jobTitle: 'Управляющий директор',
    description: 'Сбер',
    photo: require('@/assets/speakers/Лилия Алексеева.jpg')
  },
  {
    fullName: 'Иван Селеверстов',
    jobTitle: 'Партнер',
    description: 'Лидеры изменений',
    photo: require('@/assets/speakers/Иван Селеверстов.png')
  },
  {
    fullName: 'Максим Соловьев',
    jobTitle: 'Руководитель Agile-офиса',
    description: 'Россельхозбанк',
    photo: require('@/assets/speakers/Максим Соловьев.jpg')
  },
  {
    fullName: 'Михаил Соколов',
    jobTitle: 'Лидер центра компетенций DevOps, трансформатор',
    description: 'ГНИВЦ',
    photo: require('@/assets/speakers/Михаил Соколов.png')
  },
  {
    fullName: 'Василий Хахин',
    jobTitle: 'Трасформатор',
    description: 'ГНИВЦ',
    photo: require('@/assets/speakers/Василий Хахин.png')
  },
  {
    fullName: 'Марина Иванченко',
    jobTitle: 'Партнер',
    description: 'Лидеры изменений',
    photo: require('@/assets/speakers/Марина Иванченко.jpg')
  },
  {
    fullName: 'Евгений Родионов',
    jobTitle: 'CDTO',
    description: 'КЛЮЧАВТО',
    photo: require('@/assets/speakers/Евгений Родионов.png')
  },
  {
    fullName: 'Мансур Дукузов',
    jobTitle: 'Agile-коуч',
    description: 'Ростелеком',
    photo: require('@/assets/speakers/Мансур Дукузов.jpg')
  },
  {
    fullName: 'Анастасия Заруднева',
    jobTitle: 'Старший менеджер по внедрению изменений',
    description: 'Райффайзен Банк',
    photo: require('@/assets/speakers/Анастасия Заруднева.jpg')
  },
  {
    fullName: 'Алексей Мостовщиков',
    jobTitle: 'Генеральный директор',
    description: 'Агентство Цифрового Развития',
    photo: require('@/assets/speakers/Алексей Мостовщиков.jpg')
  },
  {
    fullName: 'Екатерина Бусыгина',
    jobTitle: 'Директор по планированию и внедрению продуктов больших данных',
    description: 'Билайн',
    photo: require('@/assets/speakers/Екатерина Бусыгина.jpg')
  },
  {
    fullName: 'Александр Вареник',
    jobTitle: 'Заместитель руководителя',
    description: 'Межрегиональное управление Федерального казначейства по централизованной обработке данных',
    photo: require('@/assets/speakers/Александр Вареник.jpg')
  },
  {
    fullName: 'Ольга Турова',
    jobTitle: 'Директор экспертно-аналитического центра МФТИ, руководитель вневедомственного проектного офиса',
    description: 'Министерства науки и высшего образования РФ',
    photo: require('@/assets/speakers/Ольга Турова.jpeg')
  }
]
