import { createStore } from 'vuex'
import schedule from './modules/schedule'
import activity from './modules/activity'

const store = createStore({
  state () {
    return {
      isMenuOpen: false,
      activities: null,
      stand: process.env.VUE_APP_STAND,
      code: '',
      customDomain: '',
      openedActivity: null,
      isOnlyChat: false
    }
  },
  getters: {
    isHasActivities: state => state.activities && Boolean(state.activities.length)
  },
  mutations: {
    setIsMenuOpen (state, payload) {
      state.isMenuOpen = payload
    },
    setActivities (state, payload) {
      state.activities = payload
    },
    setStand (state, payload) {
      state.stand = payload
    },
    setCode (state, payload) {
      state.code = payload
    },
    setCustomDomain (state, payload) {
      state.customDomain = payload
    },
    setOpenedActivity (state, payload) {
      state.openedActivity = payload
    },
    setIsOnlyChat (state, payload) {
      state.isOnlyChat = payload
    }
  },
  modules: {
    activity,
    schedule
  }
})

export default store
