<template>
  <section class="info">
    <default-layout-section class="info__wrapper">
      <div class="info__content">
        <h2
          v-if="title"
          class="info__title g-h2"
        >
          {{ title }}
        </h2>
        <p
          v-for="(paragraph, key) in items"
          :key="key"
          class="info__paragraph"
          v-html="paragraph"
        />
      </div>
    </default-layout-section>
  </section>
</template>

<script>
export default {
  name: 'Info',
  props: {
    title: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => ([])
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/variables";

.info {
  border-bottom: 1px solid var(--divider-backgound);

  &__wrapper {
    position: relative;
  }

  &__content {
    position: relative;
    z-index: 1;
    padding: 40px 16px;

    @media #{$md} {
      padding: 40px 24px;
    }

    @media #{$lg} {
      padding: 40px 80px;
    }

    @media #{$xl} {
      padding: 40px 100px;
    }
  }

  &__title {
    margin: 0 0 40px 0;
  }

  &__paragraph {
    margin: 0;
    white-space: pre-line;
    word-break: break-word;
    overflow-wrap: break-word;
  }

  &__paragraph + &__paragraph {
    margin-top: 20px;
  }
}
</style>
