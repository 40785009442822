<template>
  <section class="schedule">
    <default-layout-section class="schedule__wrapper">
      <div class="schedule__content">
        <div class="schedule__top">
          <h2
            v-if="title"
            class="schedule__title g-h2"
          >
            {{ title }}
          </h2>
          <app-tabs
            v-if="isShowTabs"
            class="schedule__tabs"
            @app-tabs:change="changeTab"
          />
        </div>
        <p
          v-if="isLoading"
          class="schedule__message"
        >
          Загрузка ...
        </p>
        <p
          v-else-if="isLoadingError"
          class="schedule__message"
        >
          Не получилось загрузить расписание
        </p>
        <template v-else>
          <div
            v-if="events.length"
            class="schedule__list"
          >
            <span class="schedule__time-line" />
            <event-card-comp
              v-for="(event, key) in events"
              :key="`${event.hallID}-${key}`"
              class="schedule__event"
              :event="event"
            />
          </div>
          <p
            v-else
            class="schedule__message"
          >
            Расписание не заполнено
          </p>
        </template>
      </div>
    </default-layout-section>
  </section>
</template>

<script>
import AppTabs from '@/views/components/app-tabs'
import EventCardComp from '@/views/blocks/schedule/components/event-card'
import { PLATFORM_MODE_SCHEDULE } from '@/constants/platform'
import { mapActions, mapState, mapMutations } from 'vuex'
import to from 'await-to-js'

export default {
  name: 'Schedule',
  components: { AppTabs, EventCardComp },
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      platformMode: PLATFORM_MODE_SCHEDULE,
      eventsTimeoutId: null,
      isLoading: true,
      isLoadingError: false
    }
  },
  computed: {
    ...mapState(['activities', 'openedActivity']),
    ...mapState('schedule', ['events']),
    isShowTabs () {
      return this.activities.length >= 2 || this.activities[0].items.length >= 2
    }
  },
  watch: {
    openedActivity: {
      handler (newValue) {
        if (newValue) {
          this.getActivityForEventsList(newValue)
        }
      },
      immediate: true
    }
  },
  beforeUnmount () {
    clearTimeout(this.eventsTimeoutId)
  },
  methods: {
    ...mapActions('activity', ['getActivity']),
    ...mapActions('schedule', ['getEvents']),
    ...mapMutations('schedule', ['setEvents']),
    async getActivityForEventsList (activityHash) {
      this.isLoadingError = false
      this.isLoading = true
      this.setEvents([])
      const [error, response] = await to(this.getActivity(activityHash))

      if (response) {
        const { mainHall } = response.data.activity

        if (mainHall) await this.getEventsList(mainHall)
      }

      if (error) {
        this.isLoadingError = true
        this.isLoading = false
      }
    },
    async getEventsList (hallId) {
      const [, response] = await to(this.getEvents(hallId))

      if (response) {
        this.eventsTimeoutId = setTimeout(async () => {
          await this.getEventsList(hallId)
        }, 10000)
      }

      this.isLoading = false
    },
    async switchTab (activityHash) {
      clearTimeout(this.eventsTimeoutId)
      await this.getActivityForEventsList(activityHash)
    },
    changeTab ({ hash }) {
      this.switchTab(hash)
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/variables";

.schedule {
  padding-bottom: 40px;
  border-bottom: 1px solid var(--divider-backgound);

  &__wrapper {
    position: relative;
  }

  &__content {
    position: relative;
    z-index: 1;
    padding: 40px 16px 0;

    @media #{$md} {
      padding: 40px 24px 0;
    }

    @media #{$lg} {
      padding: 40px 80px 0;
    }

    @media #{$xl} {
      padding: 40px 100px 0;
    }
  }

  &__top {
    @media #{$lg} {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;
      row-gap: 40px;
    }
  }

  &__title {
    margin: 0 0 40px 0;

    @media #{$lg} {
      margin-right: 16px;
      margin-bottom: 0;
      flex-shrink: 0;
    }
  }

  &__tabs {
    margin-bottom: 40px;

    @media #{$lg} {
      flex-shrink: 0;
      margin-bottom: 0;
    }
  }

  &__tab {
    flex-grow: 1;
    flex-shrink: 0;
    padding: 10px;
    font-size: 20px;
    line-height: 1.6;
    color: var(--tab-text-color);
    background-color: var(--tab-background);
    border: 1px solid var(--active-tab-background);
    transition: background-color 0.2s ease, color 0.2s ease;

    &_active {
      color: var(--active-tab-text-color);
      background-color: var(--active-tab-background);
    }
  }

  &__list {
    position: relative;
  }

  &__time-line {
    position: absolute;
    top: 0;
    left: 7.5px;
    width: 1px;
    height: 100%;
    background-color: var(--event-past-status-color);

    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      border: 3px solid var(--event-past-status-color);
      border-radius: 50%;
      transform: translateX(-50%);
    }

    &:before {
      top: 0;
    }

    &:after {
      bottom: 0;
    }

    @media #{$md} {
      left: 81.5px;
    }
  }

  &__event {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__message {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    min-height: 250px;
  }
}
</style>
