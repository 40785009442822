<template>
  <section class="speakers">
    <default-layout-section class="speakers__wrapper">
      <div class="speakers__content">
        <h2
          v-if="title"
          class="speakers__title g-h2"
        >
          {{ title }}
        </h2>
        <div
          ref="speakersListWrapper"
          class="speakers__list-wrapper"
        >
          <div
            ref="speakersList"
            class="speakers__list"
          >
            <card-comp
              v-for="(speaker, key) in items"
              ref="speaker"
              :key="key"
              class="speakers__item"
              :speaker="speaker"
              :shape="shape"
            />
          </div>
        </div>
        <div
          v-if="isShowDropDownList"
          class="speakers__actions"
        >
          <app-button
            class="speakers__more-button"
            @click="showMoreClickHandler"
          >
            <span :class="['speakers__more-button-icon', { 'speakers__more-button-icon_active': isShowSpeakers }]" />
            {{ isShowSpeakers ? 'Свернуть' : 'Все спикеры' }}
          </app-button>
        </div>
      </div>
    </default-layout-section>
  </section>
</template>

<script>
import CardComp from '@/views/blocks/speakers/components/card'
import AppButton from '@/views/components/app-button'

export default {
  name: 'Speakers',
  components: { CardComp, AppButton },
  props: {
    title: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => ([])
    },
    shape: {
      type: String,
      default: 'square'
    }
  },
  data () {
    return {
      isShowSpeakers: false
    }
  },
  computed: {
    isShowDropDownList () {
      return this.items.length > 4
    }
  },
  beforeMount () {
    if (this.isShowDropDownList) {
      window.addEventListener('resize', this.changeHeightSpeakersListWrapper)
    }
  },
  mounted () {
    if (this.isShowDropDownList) {
      this.hideSpeakers()
    }
  },
  beforeUnmount () {
    if (this.isShowDropDownList) {
      window.removeEventListener('resize', this.changeHeightSpeakersListWrapper)
    }
  },
  methods: {
    /**
     * Хендлер для кнопки показать/свернуть
     */
    showMoreClickHandler () {
      if (this.isShowSpeakers) {
        this.hideSpeakers()
      } else {
        this.showSpeakers()
      }

      this.isShowSpeakers = !this.isShowSpeakers
    },
    /**
     * Раскрываем список спикеров
     */
    showSpeakers () {
      const speakersListWrapperHeight = this.$refs.speakersList.offsetHeight

      this.$refs.speakersListWrapper.style.height = `${speakersListWrapperHeight}px`
    },
    /**
     * Возвращаем высоту свернутого списка
     * @returns {number|*}
     */
    getSmallHeightSpeakersListWrapper () {
      const rowGap = parseInt(getComputedStyle(this.$refs.speakersList).rowGap) // отступ между строками в grid

      switch (true) {
        case this.$isXS:
        case this.$isSM:
          // на разрешениях xs и sm свернутый список отображает 4 карточки в столбец
          // возвращаем сумму высот с 1 до 4 карточки и сумму 3 отступов
          return this.$refs.speaker.slice(0, 4).reduce((sum, item) => sum + item.$el.offsetHeight, 0) + (rowGap * 3)
        default:
          // на разрешениях xl, lg и md свернутый список отображает карточки в 1 строку
          // так как все карточки имеют одинаковую высоту в grid
          // возвращаем высоту 1 карточки
          return this.$refs.speaker[0].$el.offsetHeight
      }
    },
    /**
     * Сворачиваем список спикеров
     */
    hideSpeakers () {
      const speakersListWrapperHeight = this.getSmallHeightSpeakersListWrapper()

      this.$refs.speakersListWrapper.style.height = `${speakersListWrapperHeight}px`
    },
    /**
     * Обновляем высоту блока спикеров
     */
    changeHeightSpeakersListWrapper () {
      if (this.isShowSpeakers) {
        this.showSpeakers()
      } else {
        this.hideSpeakers()
      }
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/variables";

.speakers {
  border-bottom: 1px solid var(--divider-backgound);

  &__wrapper {
    position: relative;
  }

  &__content {
    position: relative;
    z-index: 1;
    padding: 40px 16px;

    @media #{$md} {
      padding: 40px 24px;
    }

    @media #{$lg} {
      padding: 40px 80px;
    }

    @media #{$xl} {
      padding: 40px 100px;
    }
  }

  &__title {
    margin: 0 0 40px 0;
  }

  &__list-wrapper {
    transition: height 0.5s ease;
    overflow: hidden;
    will-change: height;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 40px;

    @media #{$md} {
      grid-template-columns: repeat(3, 1fr);
      column-gap: 24px;
    }

    @media #{$xl} {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  &__more-button {
    display: flex;
    font-size: 20px;
    line-height: 1.6;
    color: var(--button-color);
    letter-spacing: 1px;
    align-items: center;
  }

  &__more-button-icon {
    position: relative;
    margin-right: 14px;
    width: 20px;
    height: 20px;
    border: 2px solid currentColor;
    border-radius: 50%;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 10px;
      height: 2px;
      background-color: currentColor;
    }

    &:before {
      transform: translate(-50%, -50%);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(90deg);
      transition: transform 0.2s ease;
    }

    &_active:after {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  }
}
</style>
