<template>
  <section class="image-block">
    <default-layout-section class="image-block__wrapper">
      <div class="image-block__content">
        <h2
          v-if="title"
          class="image-block__title g-h2"
        >
          {{ title }}
        </h2>
        <div class="image-block-main">
          <div class="image-block-main__text">
            <p
              v-for="(paragraph, key) in items"
              :key="key"
              class="image-block-main__paragraph"
              v-html="paragraph"
            />
          </div>
          <div class="image-block-main__image">
            <img
              :src="image"
              :alt="title"
            >
          </div>
        </div>
      </div>
    </default-layout-section>
  </section>
</template>

<script>
export default {
  name: 'ImageBlock',
  props: {
    title: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => ([])
    },
    image: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/variables";

.image-block {
  border-bottom: 1px solid var(--divider-backgound);

  &__wrapper {
    position: relative;
  }

  &__content {
    position: relative;
    z-index: 1;
    padding: 40px 16px;

    @media #{$md} {
      padding: 40px 24px;
    }

    @media #{$lg} {
      padding: 40px 80px;
    }

    @media #{$xl} {
      padding: 40px 100px;
    }
  }

  &__title {
    margin: 0 0 40px 0;
  }

  &-main {
    display: flex;
    flex-direction: column;
    gap: 40px;

    &__paragraph {
      margin: 0;
      white-space: pre-line;
      word-break: break-word;
      overflow-wrap: break-word;
    }

    &__paragraph + &__paragraph {
      margin-top: 20px;
    }

    &__image {
      position: relative;
      width: 100%;
      overflow: hidden;

      &:before {
        content: "";
        display: block;
        padding-top: 100%;
      }

      & img {
        position: absolute;
        object-fit: cover;
        object-position: 0 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    @media #{$md} {
      flex-direction: row;
      gap: 24px;

      &__text {
        flex-basis: 50%;
      }

      &__image {
        flex-basis: 50%;
      }
    }
  }
}
</style>
